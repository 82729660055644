import { CREDIT_KARMA_COBRANDED_HEADER } from 'lib/@getethos/experiments/feature_constants'

import { useCreditKarmaHeader } from '@/hooks/features/useCreditKarmaHeader'

import BannerLogos, { BannerLogosParam } from './BannerLogos'

const BannerLogosWrapper = (props: { moduleData: BannerLogosParam }) => {
  const { experimentKey } = props.moduleData

  const { isEnabled, isLoading } = useCreditKarmaHeader()

  const isValidExpKey =
    experimentKey && experimentKey.includes(CREDIT_KARMA_COBRANDED_HEADER.name)

  const isOffModule =
    experimentKey === CREDIT_KARMA_COBRANDED_HEADER.ruleKeys.on && !isEnabled

  const isOnModule =
    experimentKey === CREDIT_KARMA_COBRANDED_HEADER.ruleKeys.off && isEnabled

  const shouldHideModule = (isValidExpKey && isOnModule) || isOffModule

  if (shouldHideModule || isLoading) return null

  return <BannerLogos {...props} />
}

export default BannerLogosWrapper
