import React from 'react'

import { Body, CloudinaryImage, Spacer, TitleLarge } from 'ethos-design-system'

import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../global/Markdown'
import styles from './BannerLogos.module.scss'

export interface ImageGroup {
  clickthroughUrl?: string
  enableClickthrough?: boolean
  groupImage: string
  imageAlt: string
}

export interface BannerLogosParam {
  displayHeading?: boolean
  displayCta?: boolean
  heading?: any
  groups: Array<ImageGroup>
  clickthroughUrl?: string
  ctaLabel?: string
  mobileSingleRow?: boolean
  imageSize: string
  customImageWidths?: string
  customImageHeights?: string
  reducedPadding?: boolean
  reducedColumnPadding?: boolean
  separateImage?: boolean
  alignImage: string
  experimentKey?: string
  alternateLinkPadding?: boolean
  backgroundColor?: 'lightGray' | 'darkGray' | 'green' | 'white'
  extraMobilePadding?: boolean
}

const validateCustomDimension = (
  imageSize: string,
  dimension: string | undefined
): boolean => {
  if (imageSize !== 'custom' || typeof dimension !== 'string') {
    return false
  }

  const dimensionArray = dimension.split(',')
  let dimensionIsValid = dimensionArray.length === 4
  dimensionArray.forEach((d) => {
    if (Number.isNaN(Number(d))) {
      dimensionIsValid = false
    }
  })
  return dimensionIsValid
}

const BannerLogos = ({ moduleData }: { moduleData: BannerLogosParam }) => {
  const DisplayHeader = moduleData.displayHeading
  const DisplayBlockCTA = moduleData.displayCta
  const BannerLogosWrapperClasses = [styles.BannerLogos__Wrapper]
  const BannerLogosClasses = [styles.BannerLogos]
  const BannerLogosColumnClasses = [styles.BannerLogos__Column]
  const BannerLogosImageClasses = [styles.BannerLogos__Image]
  let cropMethod = CloudinaryImage.CROP_METHODS.FILL
  const imageGroup = moduleData.groups || []

  if (moduleData.alternateLinkPadding) {
    BannerLogosWrapperClasses.push(styles.AlternateLinkPadding)
  }

  if (DisplayHeader) {
    BannerLogosClasses.push(styles.BannerLogosHasCopy)
  }

  if (moduleData.mobileSingleRow) {
    BannerLogosClasses.push(styles.BannerLogosSingleRow)
  }

  if (moduleData.reducedPadding) {
    BannerLogosWrapperClasses.push(styles.ReducedPadding)
  }

  if (moduleData.separateImage) {
    BannerLogosWrapperClasses.push(styles.BannerLogosSeparated)
    BannerLogosClasses.push(styles.BannerLogosSeparated)
  }

  if (moduleData.reducedColumnPadding) {
    BannerLogosColumnClasses.push(styles.ReducedColumnPadding)
  }

  if (
    moduleData.backgroundColor &&
    moduleData.backgroundColor !== 'lightGray' // default if unset
  ) {
    BannerLogosWrapperClasses.push(styles[moduleData.backgroundColor])
  }

  if (moduleData.extraMobilePadding) {
    BannerLogosColumnClasses.push(styles.ExtraMobilePadding)
  }

  switch (moduleData.alignImage) {
    case 'left':
      BannerLogosClasses.push(styles.BannerLogosLeft)
      break
    case 'right':
      BannerLogosClasses.push(styles.BannerLogosRight)
      break
    default:
      BannerLogosClasses.push(styles.BannerLogosCenter)
  }

  const customHeightsArray = validateCustomDimension(
    moduleData.imageSize,
    moduleData.customImageHeights
  )
    ? moduleData.customImageHeights!.split(',')
    : []

  const customWidthsArray = validateCustomDimension(
    moduleData.imageSize,
    moduleData.customImageWidths
  )
    ? moduleData.customImageWidths!.split(',')
    : []

  switch (moduleData.imageSize) {
    case 'medium':
      BannerLogosColumnClasses.push(styles.ImageMedium)
      BannerLogosImageClasses.push(styles.ImageMedium)
      if (imageGroup.length === 1) {
        BannerLogosImageClasses.push(styles.SingleImage)
      }
      break
    case 'large':
      BannerLogosColumnClasses.push(styles.ImageLarge)
      BannerLogosImageClasses.push(styles.ImageLarge)
      if (imageGroup.length === 1) {
        BannerLogosImageClasses.push(styles.SingleImage)
      }
      break
    case 'custom':
      if (customHeightsArray.length > 0 && customWidthsArray.length > 0) {
        BannerLogosImageClasses.push(styles.NoMaxWidthHeight)
        cropMethod = CloudinaryImage.CROP_METHODS.FIT
      }
      break
  }

  return (
    <div className={BannerLogosWrapperClasses.join(' ')}>
      {DisplayHeader && (
        <div className={styles.BannerLogos__Text}>
          <div className={styles.Module__Headings}>
            <TitleLarge.Serif.Book500>
              <Markdown
                input={moduleData.heading}
                allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
              />
            </TitleLarge.Serif.Book500>
          </div>
          {DisplayBlockCTA && (
            <div className={styles.BannerLogos__CTA}>
              <Spacer.H8 />
              <Body.Regular400>
                <a href={moduleData.clickthroughUrl}>
                  <span>{moduleData.ctaLabel}</span> &#8594;
                </a>
              </Body.Regular400>
            </div>
          )}
        </div>
      )}
      <div className={BannerLogosClasses.join(' ')}>
        {imageGroup.map((group, idx) => (
          <div className={BannerLogosColumnClasses.join(' ')} key={idx}>
            {group.enableClickthrough ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={group.clickthroughUrl}
              >
                <CloudinaryImage
                  publicId={group.groupImage}
                  alt={group.imageAlt}
                  className={BannerLogosImageClasses.join(' ')}
                  width={customWidthsArray}
                  height={customHeightsArray}
                  crop={cropMethod}
                />
              </a>
            ) : (
              <CloudinaryImage
                publicId={group.groupImage}
                alt={group.imageAlt}
                className={BannerLogosImageClasses.join(' ')}
                width={customWidthsArray}
                height={customHeightsArray}
                crop={cropMethod}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default BannerLogos
